import {inject, Inject, Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from './Notification.service';

@Injectable({
    providedIn: 'root'
})
export class FaultyEcuBuildsService {
    private url = `${this.API_URL}`;
    public faultyEcuBuilds = signal<string[]>([]);
    public pageNumber = signal<number>(0);
    public pageSize = signal<number>(50);
    public isLoading = signal<boolean>(false);
    public searchString = signal('');

    notificationService = inject(NotificationService);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    reset() {
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.faultyEcuBuilds.set([]);
        this.getFaultyEcuBuilds();
    }

    triggerFaultyEcuBuildRequest() {
        return this.httpClient.get<string[]>(`${this.url}/ecuBuildNames/faultyEcuBuilds`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            },
        });
    }

    getFaultyEcuBuilds() {
        this.isLoading.set(true);
        this.triggerFaultyEcuBuildRequest().subscribe({
            next: (response) => {
                this.faultyEcuBuilds.set(response['content']);
                this.isLoading.set(false);
            },
            error: () => this.isLoading.set(false),
        });
    }

    getAllSuggestedFaultyEcuBuilds(ecuBuild: string) {
        this.faultyEcuBuilds.set([]);
        return this.httpClient.get<string[]>(`${this.url}/ecuBuildNames/suggestFaultyEcuBuilds`, {
            params: {
                ecuBuild,
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
            },
        }).subscribe(response => this.faultyEcuBuilds.set([...response["content"]]));
    }


    increasePageNumber() {
        this.pageNumber.update((prevNum) => prevNum + 1);
    }

    onScroll() {
        this.increasePageNumber();
        this.triggerFaultyEcuBuildRequest().subscribe({
            next: (response) => {
                this.faultyEcuBuilds.update((currentList) => [
                    ...currentList,
                    ...response['content'],
                ]);
                this.isLoading.set(false);
            },
            error: () => this.isLoading.set(false),
        });
    }
}
