import {computed, inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {EcuBuildNameModel} from "../models/EcuBuildName-model";
import {NotificationService} from "./Notification.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {of} from "rxjs";
import {CorrectEcuBuildModel} from "../uploading-actions/user-inputs/user-inputs.component";

@Injectable({
    providedIn: "root"
})
export class EcuNamesService {

    private url = `${this.API_URL}`;

    public ecuNames = signal<EcuBuildNameModel[]>([]);
    public ecuNamesIsLoading = signal<boolean>(false);
    public displayedTableHeaders = signal<string[]>([]);
    public searchQuery = signal('');
    public showInputRow = signal(false);
    public newRow = signal<EcuBuildNameModel>({} as EcuBuildNameModel);
    public ecuToEditIndex = signal<number | null>(null);
    public isWaitingResponse = signal<boolean>(false);
    public correctEcuBuildNames = signal<CorrectEcuBuildModel[]>([]);
    public pageNumber = signal<number>(0);
    public pageSize = signal<number>(50);


    public notificationService = inject(NotificationService);
    private dialog = inject(MatDialog);

    constructor(@Inject('API_URL') private API_URL: string, private httpClient: HttpClient) {
    }

    getEcuBuildNames() {
        this.ecuNamesIsLoading.set(true);
        this.httpClient.get<EcuBuildNameModel[]>(`${this.url}/ecuBuildNames`).subscribe(response => {
            this.ecuNames.set(response);
            this.ecuNamesIsLoading.set(false);
        })
    }


    getEcuBuildOnScroll() {
        this.ecuNamesIsLoading.set(true);
        this.httpClient.get<EcuBuildNameModel[]>(`${this.url}/ecuBuildNames`).subscribe(response => {
            this.ecuNames.update((ecuNames) => [...ecuNames, ...response]);
            this.ecuNamesIsLoading.set(false);
        })
    }


    onScrollEcu() {
        this.increasePageNumber();
        this.getEcuBuildOnScroll();
    }

    increasePageNumber() {
        this.pageNumber.update((pageNum) => pageNum + 1)
    }


    async getCorrectEcuNamesOptions() {
        try {
            const response = await this.httpClient.get<EcuBuildNameModel[]>(`${this.url}/ecuBuildNames`).toPromise();
            let availableTunings = [];
            for (let option of response) {
                if (availableTunings.indexOf(option.correctEcuBuildNames) === -1) {
                    availableTunings.push(option);
                }
            }
            this.correctEcuBuildNames.set(availableTunings);
        } catch (error) {
            console.error("Error occurred while fetching ECU build names:", error);
        }
    }

    ecuNamesFiltered = computed(() => {
        const list = this.ecuNames();

        if (this.searchQuery()?.length >= 1) {
            return list.filter(item =>
                item.correctEcuBuildNames.toLowerCase().startsWith(this.searchQuery().toLowerCase()) ||
                (item.connectedEcuBuildNames && item.connectedEcuBuildNames.toLowerCase().startsWith(this.searchQuery().toLowerCase())) ||
                item.acceptedEcuBuildNames.toLowerCase().startsWith(this.searchQuery().toLowerCase())
            );
        } else {
            return list;
        }
    })

    checkIfEcuBuildIsInDatabase(ecuBuild: string) {
        const ecuBuildExists = this.ecuNames().some(value => value.correctEcuBuildNames == ecuBuild);
        return ecuBuildExists ? of({ecuBuildExists: true}) : of(null);
    }

    addNewEcuBuildItem(newRow: EcuBuildNameModel) {
        this.isWaitingResponse.set(true);
        this.httpClient.post<EcuBuildNameModel>(`${this.url}/ecuBuildNames`, {
            id: newRow.id,
            correctEcuBuildNames: newRow.correctEcuBuildNames,
            connectedEcuBuildNames: newRow.connectedEcuBuildNames,
            acceptedEcuBuildNames: newRow.acceptedEcuBuildNames,
            correctEcuProducer: newRow.correctEcuProducer
        }, {observe: "response"}).subscribe(response => {
            if (response.status === 201) {
                this.ecuNames.update((ecuNames) => [...ecuNames, response.body]);
                this.isWaitingResponse.set(false);
                this.notificationService.showSuccessMessage('Successfully added.', `Successfully added new ecu build.`);
            }
            this.showInputRow.set(false);
            this.resetNewRowValue();
        }, (error: HttpErrorResponse) => {
            this.isWaitingResponse.set(false);
            if (error.status === 400) {
                this.notificationService.showErrorMessage("Something went wrong!", `Error occurred`);
            }
            if (error.error?.errors) {
                const errorMessage = error.error?.errors;
                for (const [key, value] of Object.entries(errorMessage)) {
                    this.notificationService.showErrorMessage(value as string, key['Error occurred']);
                }
            }
        });
    }

    updateEcuBuild(ecuBuild: EcuBuildNameModel, id: number) {
        this.isWaitingResponse.set(true);
        this.httpClient.put<EcuBuildNameModel>(`${this.url}/ecuBuildNames/${id}`, {
            correctEcuBuildNames: ecuBuild.correctEcuBuildNames,
            connectedEcuBuildNames: ecuBuild.connectedEcuBuildNames,
            acceptedEcuBuildNames: ecuBuild.acceptedEcuBuildNames,
            correctEcuProducer: ecuBuild.correctEcuProducer
        }, {observe: "response"}).subscribe(response => {
            if (response.status === 200) {
                this.ecuNames.update((ecuNames) => ecuNames.map((ecuName) => (ecuName.id === ecuBuild.id) ? response.body : ecuName));
                this.isWaitingResponse.set(false);
                this.notificationService.showSuccessMessage("Successfully updated", "Ecu name element updated");
            } else {
                this.notificationService.showErrorMessage("Something went wrong.", "Error!");
            }
            this.closeEditedItem();
        })
    }


    deleteEcuBuildItem(ecuBuild: EcuBuildNameModel) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete Ecu?"
        })
        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                const index = this.ecuNames().indexOf(ecuBuild);
                if (index != -1) {
                    this.deleteEcuBuildItemRequest(ecuBuild.id);
                }
            }
        })
    }

    deleteEcuBuildItemRequest(id: number) {
        this.httpClient.delete(`${this.url}/ecuBuildNames/${id}`, {observe: "response"}).subscribe((response) => {
            if (response.status === 200) {
                this.ecuNames.update((ecuNames) => ecuNames.filter((ecuName) => ecuName.id !== id));
                this.notificationService.showSuccessMessage('Successfully deleted', `Ecu Build ${id} deleted.`);
            } else {
                this.notificationService.showErrorMessage('Something went wrong', 'Error',);
            }
        });
    }

    searchEcuNames(searchQuery: string) {
        this.searchQuery.set(searchQuery);
    }

    resetNewRowValue() {
        this.newRow.set({} as EcuBuildNameModel);
    }

    closeEditedItem() {
        this.ecuToEditIndex.set(null);
    }

}
