import {Component, inject, OnInit, signal} from '@angular/core';
import {FaultyEcuBuildsService} from "../../service/FaultyEcuBuilds.service";

import {fadeInAnimation} from "../../animations/fadeInAnimation";

@Component({
    selector: 'app-faulty-ecu-builds',
    animations: [fadeInAnimation],
    host: {'[@fadeInAnimation]': ''},
    templateUrl: './faulty-ecu-builds.component.html',
    styleUrl: './faulty-ecu-builds.component.css'
})
export class FaultyEcuBuildsComponent implements OnInit {

    public faultyEcuBuildsService = inject(FaultyEcuBuildsService);
    public faultyEcuBuild = signal<string>("");

    constructor() {
    }

    ngOnInit() {
        this.faultyEcuBuildsService.reset();
        this.faultyEcuBuildsService.getFaultyEcuBuilds();
    }

    getFaultyEcuBuild($event: any) {
        this.faultyEcuBuild.set($event.valueOf());
    }

    onSearchInput($event: any) {
        this.faultyEcuBuildsService.searchString.set($event.valueOf());

        if (this.faultyEcuBuildsService.searchString().length >= 3) {
            this.faultyEcuBuildsService.pageNumber.set(0);
            this.faultyEcuBuildsService.pageSize.set(50);
            this.faultyEcuBuildsService.getAllSuggestedFaultyEcuBuilds(this.faultyEcuBuildsService.searchString());
        }
        if (this.faultyEcuBuildsService.searchString().length === 0) {
            this.faultyEcuBuildsService.reset();
        }
    }


}
