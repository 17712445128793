<div class="main-wrapper">
    <div class="act-search-bar">
        <app-search-bar (searchValue)="onSearchInput($event)" [value]="'Faulty Ecu Build'"
                        [newInputValue]="faultyEcuBuildsService.searchString()"></app-search-bar>
    </div>
    <div class="main">
        <div class="faulty-table-wrapper">
            <app-faulty-ecu-builds-table [faultyEcuBuildList]="faultyEcuBuildsService.faultyEcuBuilds()"
                                         (faultyEcuBuild)="getFaultyEcuBuild($event)"/>
        </div>
        <div class="pure-original-table">
            @if (faultyEcuBuild()) {
                <app-pure-original-faulty-ecu-table [faultyEcuBuild]="faultyEcuBuild()"/>
            }

        </div>
    </div>
</div>
