<div class="main">
    @defer (when !pureOriginalsService.tableIsLoading()) {
        <div class="table-container"
             infinite-scroll
             [scrollWindow]="false"
             [infiniteScrollDistance]="2"
             (scrolled)="pureOriginalsService.onScrollFaultyPureOriginalsByFaultyEcuBuildsTable()">

            <app-pure-originals-table
                    [pureOriginals]="pureOriginalsService.pureOriginals()"
                    [actionsTemplate]="customActions"
                    [sortDirectionValues]="pureOriginalsService.sortDirectionValues()"
                    [displayedTableHeaders]="pureOriginalsService.displayedTableHeaders()"
                    [displayedOriginalColumns]="pureOriginalsService.displayedOriginalColumns()"
                    [headerActionButton]="true"
                    (selectedIdsOutput)="getSelectedIdOutputs($event)"
            />

            <ng-template #customActions let-element>
                <div class="actions-row">
                    <div class="checkbox-wrapper-18">
                        <div class="round">
                            <input type="checkbox" [id]="element.id"
                                   [checked]="isElementSelected(element.id)"
                                   (change)="viewDetails($event, element)"/>
                            <label [for]="element.id"></label>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    } @placeholder (minimum 300ms) {
        <div class="skeleton-wrapper">
            <app-skeleton-table></app-skeleton-table>
        </div>
    }

    <div class="button-container">
        <button class="submit-button" (click)="submitPureOriginalIds()">Submit</button>
    </div>
</div>
