import {Component, EventEmitter, inject, input, Output} from '@angular/core';
import {FaultyEcuBuildsService} from "../../../service/FaultyEcuBuilds.service";
import {PureOriginalService} from "../../../service/pure-original.service";

@Component({
    selector: 'app-faulty-ecu-builds-table',
    templateUrl: './faulty-ecu-builds-table.component.html',
    styleUrl: './faulty-ecu-builds-table.component.css'
})
export class FaultyEcuBuildsTableComponent {

    faultyEcuBuildsService = inject(FaultyEcuBuildsService);
    pureOriginalService = inject(PureOriginalService);
    @Output() faultyEcuBuild = new EventEmitter<string>();
    faultyEcuBuildList = input.required<string[]>();

    constructor() {
    }

    getFaultyPureOriginalsByFaultyEcuBuild(ecuBuild: string) {
        this.faultyEcuBuild.emit(ecuBuild);
    }

}
