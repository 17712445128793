export class PureOriginalsModel {
    public id: string;
    public winOlsReference: string;
    public ecuProducer: string;
    public ecuBuild: string;
    public winOlsDbName: string;
    public blobZipName: string;
    public md5: string;
    public sha1: string;
    public sha256: string;
    public clientImportCounter: string;
    public masterUploadCounter: string;
    public dateUploaded: string;
    public numberOfOptions: string;
    public vehicleProducer: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public vehicleModelYear: string;
    public vehicleBuild: string;
    public ecuSoftwareVersion: string;
    public ecuUse: string;
    public engineDisplacement: string;
    public engineMaxTorque: string;
    public engineName: string;
    public engineProducer: string;
    public engineType: string;
    public engineTransmission: string;
    public outputPs: string;
    public outputKw: string;
    public vehicleType: string;
    public ecuProdNr: string;
    public ecuSoftwareVersionVersion: string;
    public engineEmissionStandard: string;
    public resellerProjectDetails: string;
    public vehicleVin: string;
    public dateUpdated: string;
    public vehicleCharacteristic: string;
    public ecuStgNr: string;
    public resellerProjectType: string;
    public resellerCredits: string;
    public projectComment: string;
    public versionComment: string;
    public clientName: string;
    public clientNumber: string;
    public clientLicencePlace: string;
    public readingHardware: string;
    public softwareNumber: string;
    public engineEmission: string;
    public creditType: string;
    public projectType: string;
    public dateExported: string
    public userDef1: string;
    public userDef2: string;
    public userDef3: string;
    public userDef4: string;
    public userDef5: string;
    public userDef6: string;
    public userDef7: string;
    public userDef11: string;
    public userDef12: string;
    public userDef13: string;
    public userDef14: string;
    public userDef15: string;
    public userDef16: string;
    public userDef17: string;
    public userDef18: string;
    public userDef19: string;
    public userDef20: string;




    constructor() {
        this.id = "id";
        this.winOlsReference = "winOlsReference";
        this.ecuProducer = "ecuProducer";
        this.ecuBuild = "ecuBuild";
        this.winOlsDbName = "winOlsDbName"
        this.clientImportCounter = "clientImportCounter";
        this.masterUploadCounter = "masterUploadCounter";
        this.dateUploaded = "dateUploaded";
        this.numberOfOptions = "numberOfOptions";
        this.vehicleProducer = "vehicleProducer";
        this.vehicleSeries = "vehicleSeries";
        this.vehicleModel = "vehicleModel";
        this.vehicleModelYear = "vehicleModelYear";
        this.vehicleBuild = "vehicleBuild";
        this.ecuSoftwareVersion = "ecuSoftwareVersion";
        this.ecuUse = "ecuUse";
        this.engineDisplacement = "engineDisplacement";
        this.engineMaxTorque = "engineMaxTorque";
        this.engineName = "engineName";
        this.engineProducer = "engineProducer";
        this.engineType = "engineType";
        this.engineTransmission = "engineTransmission";
        this.outputPs = "outputPs";
        this.outputKw = "outputKw";
        this.md5 = "md5";
        this.sha1 = "sha1";
        this.sha256 = "sha256";
        this.blobZipName = "blobZipName";
        this.vehicleType = "vehicleType";
        this.ecuSoftwareVersionVersion = "ecuSoftwareVersionVersion";
        this.engineEmissionStandard = "engineEmissionStandard";
        this.resellerProjectDetails = "resellerProjectDetails";
        this.vehicleVin = "vehicleVin";
        this.dateUpdated = "dateUpdated";
        this.ecuProdNr = "ecuProdNr";
        this.vehicleCharacteristic = "vehicleCharacteristic";
        this.ecuStgNr = "ecuStgNr";
        this.resellerProjectType = "resellerProjectType";
        this.resellerCredits = "resellerCredits";
        this.projectComment = "projectComment";
        this.versionComment = "versionComment";
        this.clientName = "clientName";
        this.clientNumber = "clientNumber"
        this.clientLicencePlace = "clientLicencePlace";
        this.readingHardware = "readingHardware";
        this.softwareNumber = "softwareNumber";
        this.engineEmission = "engineEmission";
        this.creditType = "creditType";
        this.projectType = "projectType";
        this.dateExported = "dateExported";
        this.userDef1 = "userDef1";
        this.userDef2 = "userDef2";
        this.userDef3 = "userDef3";
        this.userDef4 = "userDef4";
        this.userDef5 = "userDef5";
        this.userDef6 = "userDef6";
        this.userDef7 = "userDef7";
        this.userDef11 = "userDef11";
        this.userDef12 = "userDef12";
        this.userDef13 = "userDef13";
        this.userDef14 = "userDef14";
        this.userDef15 = "userDef15";
        this.userDef16 = "userDef16";
        this.userDef17 = "userDef17";
        this.userDef18 = "userDef18";
        this.userDef19 = "userDef19";
        this.userDef20 = "userDef20";
    }
}
