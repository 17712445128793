<div class="dialog-content">
    <div mat-dialog-title class="dialog-title">
        <span>Update Faulty Ecu Build</span>
        <button (click)="canceledOption()"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="underline"></div>
    @if (!showEcuBuildInput()) {
        <div>
            <div mat-dialog-content class="dialog-text">Are you sure that you want to update this Ecu
                Build: {{ data?.ecuBuild }}?
            </div>
            <div mat-dialog-actions>
                <button mat-raised-button color="warn" (click)="confirmedOption()">Yes</button>
                <button mat-raised-button class="dialog-btn" (click)="canceledOption()">No</button>
            </div>
        </div>
    } @else {
        <div>
            <div mat-dialog-content class="dialog-text">

                <mat-form-field>
                    <mat-label>New ECU Build</mat-label>
                    <input matInput [formControl]="ecuBuildNameControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{ option.correctEcuBuildNames }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <div mat-dialog-actions>
                <button mat-raised-button color="warn" [disabled]="ecuBuildNameControl.invalid"
                        (click)="submitNewEcuBuild()">Yes
                </button>
                <button mat-raised-button class="dialog-btn" (click)="canceledOption()">No</button>
            </div>
        </div>
    }
</div>
