import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LayoutComponent} from "./layout/layout.component";
import {FileServiceTableComponent} from "./file-service-table/file-service-table.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AllMastersDatabaseComponent} from "./all-masters-database/all-masters-database.component";
import {PureOriginalsComponent} from "./pure-originals/pure-originals.component";
import {MasterResultsComponent} from "./master-results/master-results.component";
import {ResellerRecordsComponent} from "./reseller-records/reseller-records.component";
import {MasterRecordsComponent} from "./all-masters-database/master-records/master-records.component";
import {PureOriginalDetailsComponent} from "./pure-originals/pure-original-details/pure-original-details.component";
import {LoginPageComponent} from "./login-page/login-page.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {UploadingActionsComponent} from "./uploading-actions/uploading-actions.component";
import {SystemInfoChartComponent} from "./statistics/system-info-chart/system-info-chart.component";
import {PureOriginalsResultsComponent} from "./pure-originals-results/pure-originals-results.component";
import {
    RequestedTunings
} from "./requested-tunings-wrapper/requested-tunings/requested-tunings";
import {
    PureOriginalResultsTableComponent
} from "./pure-originals-results/pure-original-results-table/pure-original-results-table.component";
import {
    RequestedTuningsTables
} from "./requested-tunings-wrapper/requested-tunings/requested-tunings-tables/requested-tunings-tables";
import {RegisterUserComponent} from "./register-user/register-user.component";
import {RegistrationTemplateComponent} from "./register-user/registration-template/registration-template.component";
import {ActivationTemplateComponent} from "./register-user/activation-template/activation-template.component";
import {UserSettingsPageComponent} from "./user-settings-page/user-settings-page.component";
import {AdminGuardService} from "./service/guards/admin-guard.service";
import {AdminPanelComponent} from "./admin-panel/admin-panel.component";
import {AuthGuard} from "./service/guards/auth.guard";
import {AllUsersTableComponent} from "./admin-panel/all-users-table/all-users-table.component";
import {TuningNamesTableComponent} from "./tuning-names-wrapper/tuning-names-table/tuning-names-table.component";

import {EcuBuildNamesTableComponent} from "./ecu-names-wrapper/ecu-build-names-table/ecu-build-names-table.component";
import {FaultyMastersComponent} from "./ecu-names-wrapper/faulty-masters/faulty-masters.component";
import {EcuNamesWrapperComponent} from "./ecu-names-wrapper/ecu-names-wrapper.component";
import {TuningNamesWrapperComponent} from "./tuning-names-wrapper/tuning-names-wrapper.component";
import {MasterDetailsWrapperComponent} from "./master-details-wrapper/master-details-wrapper.component";
import {RequestedTuningsWrapperComponent} from "./requested-tunings-wrapper/requested-tunings-wrapper.component";
import {ModeratorGuardService} from "./service/guards/moderator-guard.service";
import {
    PureOriginalTableWrapperComponent
} from "./pure-originals/pure-original-table-wrapper/pure-original-table-wrapper.component";
import {
    PureOriginalsMatchingDetailsTableComponent
} from "./pure-originals-matching-details-table/pure-originals-matching-details-table.component";
import {ForgotPasswordComponent} from "./login-page/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {
    ForgotPasswordVerificationComponent
} from "./login-page/forgot-password/forgot-password-verification/forgot-password-verification.component";
import {CorrectEcuProducersComponent} from "./correct-ecu-producers/correct-ecu-producers.component";
import {WinOlsTransactionComponent} from "./win-ols-transaction/win-ols-transaction.component";
import {
    TwoFactorLoginTemplateComponent
} from "./login-page/two-factor-login-template/two-factor-login-template.component";
import {LoginFormComponent} from "./login-page/login-form/login-form.component";
import {AllUsersStatisticsComponent} from "./statistics/user-statistics-wrapper/all-users-statistics/all-users-statistics.component";
import {
    UsersStatisticsDetailsComponent
} from "./statistics/user-statistics-wrapper/users-statistics-details/users-statistics-details.component";
import {UserStatisticsWrapperComponent} from "./statistics/user-statistics-wrapper/user-statistics-wrapper.component";
import {FaultyPureOriginalsComponent} from "./ecu-names-wrapper/faulty-pure-originals/faulty-pure-originals.component";
import {
    ResellerTuningCombinationsComponent
} from "./reseller-tuning-combinations/reseller-tuning-combinations.component";
import {FaultyEcuBuildsComponent} from "./ecu-names-wrapper/faulty-ecu-builds/faulty-ecu-builds.component";

const routes: Routes = [
    {
        path: 'app', component: LayoutComponent, canActivate: [AuthGuard], children: [
            {path: '', component: DashboardComponent},
            {
                path: 'admin-panel', component: AdminPanelComponent, canActivate: [AdminGuardService], children: [
                    {path: '', component: AllUsersTableComponent},
                ]
            },
            {
                path: 'statistics', component: StatisticsComponent, canActivate: [AdminGuardService], children: [
                    {path: 'system-info', component: SystemInfoChartComponent}
                ]
            },

            {path: 'upload-files', component: UploadingActionsComponent},
            {path: 'file-service', component: FileServiceTableComponent, canActivate: [AdminGuardService]},
            {
                path: 'master-database',
                component: AllMastersDatabaseComponent,
                canActivate: [AdminGuardService],
                children: [
                    {path: '', component: MasterRecordsComponent}
                ]
            },
            {
                path: 'pure-originals',
                component: PureOriginalsComponent,
                canActivate: [ModeratorGuardService],
                children: [
                    {path: '', component: PureOriginalTableWrapperComponent},
                    {path: 'pure-original-details', component: PureOriginalDetailsComponent},
                ]
            },
            {
                path: 'pure-originals-matching-table',
                component: PureOriginalsMatchingDetailsTableComponent,
                canActivate: [ModeratorGuardService]
            },
            {
                path: 'master-details/:id',
                component: MasterDetailsWrapperComponent,
            },
            {
                path: 'custom-functions',
                loadComponent: () => import('./custom-functions/custom-functions.component').then(m => m.CustomFunctionsComponent)
            },

            {
                path: 'requested-tunings',
                component: RequestedTuningsWrapperComponent,
                canActivate: [ModeratorGuardService],
                children: [
                    {path: '', component: RequestedTunings},
                    {path: 'requested-tunings-tables', component: RequestedTuningsTables}
                ]
            },
            {
                path: 'pure-originals-results',
                component: PureOriginalsResultsComponent,
                canActivate: [AdminGuardService],
                children: [
                    {path: '', component: PureOriginalResultsTableComponent,},
                ]
            },
            {
                path: 'results-card', component: RequestedTunings, children: [
                    {path: '', component: RequestedTuningsTables}
                ]
            },
            {
                path: 'ecu-names', component: EcuNamesWrapperComponent, canActivate: [AdminGuardService], children: [
                    {path: 'ecu-build-names', component: EcuBuildNamesTableComponent},
                    {
                        path: 'correct-ecu-producers',
                        component: CorrectEcuProducersComponent,
                    },
                    {path: 'faulty-masters', component: FaultyMastersComponent},
                    {path: 'faulty-pure-originals', component: FaultyPureOriginalsComponent},
                    {path: 'faulty-ecu-builds', component: FaultyEcuBuildsComponent}
                ]
            },

            {
                path: 'tuning-wrapper-names',
                component: TuningNamesWrapperComponent,
                canActivate: [AdminGuardService],
                children: [
                    {path: 'tuning-names', component: TuningNamesTableComponent},
                    {path: 'reseller-tuning-combinations', component: ResellerTuningCombinationsComponent}
                ]
            },

            {path: 'masters-results', component: MasterResultsComponent, canActivate: [AdminGuardService]},
            {path: 'reseller-results', component: ResellerRecordsComponent, canActivate: [AdminGuardService]},
            {path: 'user-settings', component: UserSettingsPageComponent},
            {path: 'winOls-transactions', component: WinOlsTransactionComponent},
            {
                path: 'user-statistics', component: UserStatisticsWrapperComponent, children: [
                    {path: "", redirectTo: "all-users", pathMatch: "full"},
                    {path: "all-users", component: AllUsersStatisticsComponent},
                    {path: ':id', component: UsersStatisticsDetailsComponent}
                ]
            },
        ]
    },
    {
        path: 'login', component: LoginPageComponent, children: [
            {path: "", component: LoginFormComponent},
            {path: "two-factor", component: TwoFactorLoginTemplateComponent},
        ]
    },
    {path: "forgot-password", component: ForgotPasswordComponent},
    {path: "forgot-password-verification", component: ForgotPasswordVerificationComponent},
    {path: "reset-password", component: ResetPasswordComponent},
    {path: 'register', component: ActivationTemplateComponent},
    {path: 'signup', component: RegisterUserComponent},
    {path: 'verification', component: RegistrationTemplateComponent},
    {path: '', redirectTo: 'app', pathMatch: "full"}

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
