import {inject, Inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {AllMasterDatabaseModel} from "../models/AllMasterDatabase-model";
import {SearchFilterModel} from "../models/SearchFilter-model";
import {UpdateMasterModel} from "../models/UpdateMaster-model";
import {forkJoin, map, Observable} from "rxjs";
import {DeleteDialogComponent} from "../helpers/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteFileFromDatabaseService} from "./DeleteFileFromDatabase.service";
import {NotificationService} from "./Notification.service";
import {VersionRecordsModel} from "../models/VersionRecordsModel";
import {DownloadFileFromDatabaseService} from "./DownloadFileFromDatabase.service";
import {EditMasterDialogComponent} from "../helpers/edit-master-dialog/edit-master-dialog.component";
import {CarImageService} from "./CarImage.service";
import {SortDirectionValuesModel} from "../models/SortDirectionValues-model";
import {CustomMasterUploadFileValidations} from "../master-details-wrapper/master-details/master-details.component";
import {FileUploadService} from "./fileUpload.service";
import {Location} from "@angular/common";


@Injectable({
    providedIn: "root"
})
export class AllMastersDatabaseService {
    private url = `${this.API_URL}`;

    public allMasters = signal<AllMasterDatabaseModel[]>([]);
    public faultyMasters = signal<AllMasterDatabaseModel[]>([]);
    public masterItem = signal<AllMasterDatabaseModel>({} as AllMasterDatabaseModel);
    public expandedMaster = signal<AllMasterDatabaseModel | null>(null);
    public masterItems = signal<AllMasterDatabaseModel>(new AllMasterDatabaseModel());
    public displayedColumns = signal<(keyof AllMasterDatabaseModel)[]>([]);
    public displayedTableHeaders = signal<string[]>([]);
    public versionItems = signal<VersionRecordsModel>(new VersionRecordsModel());
    public displayedVersionColumns = signal<(keyof VersionRecordsModel)[]>([]);

    public sortDirectionValues = signal<SortDirectionValuesModel>({active: "ecuBuild", direction: "desc"});
    private filesToDownload = signal<number[]>([]);
    public searchString = signal('');
    public selectedFilters = signal<SearchFilterModel[]>([]);
    public searchedResults = signal<AllMasterDatabaseModel[]>([]);
    public pageNumber = signal<number>(0);
    public pageSize = signal<number>(50);
    private sortAttribute = signal<string>('id');
    public masterId = signal<number>(-1);
    public ecuBuild = signal<string>('');
    public ecuProducer = signal<string>('');

    public isLoading = signal<boolean>(false);
    public openAdvanceSettings = signal<boolean>(false);
    openMasterIniUpload = signal<boolean>(false)
    public masterDetailsIsLoading = signal<boolean>(false);

    public myInputs = new Map<string, CustomMasterUploadFileValidations>([
        ["ini", {
            message: '',
            hasError: false,
        }]
    ])

    public progressWidth = signal<number>(0);
    public resetSignal = signal<boolean>(false);
    public inputText = "Drag & Drop INI file";

    private httpClient = inject(HttpClient);
    private carImageService = inject(CarImageService);
    private dialog = inject(MatDialog);
    private deleteFileFromDatabaseService = inject(DeleteFileFromDatabaseService);
    private notificationService = inject(NotificationService);
    private downloadService = inject(DownloadFileFromDatabaseService);
    private fileUploadService = inject(FileUploadService);
    private location = inject(Location);

    constructor(@Inject('API_URL') private API_URL: string) {
    }

    getAllTableHeaderNames() {
        let objectKeys = Object.keys(this.masterItems());
        for (let key of objectKeys) {
            this.displayedColumns().push(key as keyof AllMasterDatabaseModel);
        }
        this.displayedTableHeaders.set(['arrow', ...this.displayedColumns(), 'actions']);
    }

    getAllVersionHeaderNames() {
        let objKeys = Object.keys(this.versionItems());
        for (let key of objKeys) {
            this.displayedVersionColumns().push(key as keyof VersionRecordsModel);
        }
    }


    triggerAllMasterRecordsRequest() {
        return this.httpClient.get<AllMasterDatabaseModel[]>(`${this.url}/master/all`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortDirectionValues().active,
                sortDirection: this.sortDirectionValues().direction
            }
        });
    }

    getAllMasterRecords() {
        this.isLoading.set(true);
        this.triggerAllMasterRecordsRequest().subscribe((response) => {
            this.allMasters.set(response["content"]);
            this.isLoading.set(false);
        })
    }

    onScroll() {
        if (this.selectedFilters().length === 0 && this.searchString().length < 3) {
            this.increasePageNumber();
            this.triggerAllMasterRecordsRequest().subscribe(response => {
                this.allMasters.update((allMasters) => [...allMasters, ...response["content"]]);
            });
        }
        if (this.selectedFilters().length === 0 && this.searchString().length >= 3) {
            this.increasePageNumber();
            this.getAllMastersByWinOlsScrollable(this.searchString());
        }
        if (this.selectedFilters().length > 0) {
            this.increasePageNumber();
            this.getAllMastersAdvancedFiltered();
        }
    }


    onScrollFaulty() {
        this.increasePageNumber();
        this.getFaultyMasters()
    }


    getAllMastersFilteredByMasterWinOls(winOls: string) {
        return this.httpClient.get<{ content: AllMasterDatabaseModel[] }>(`${this.url}/master/searchByWinOls`, {
                params: {
                    pageNumber: this.pageNumber(),
                    pageSize: this.pageSize(),
                    winOlsReference: winOls,
                }
            }
        );
    }

    getAllMastersFilteredByVersionWinOls(winOls: string) {
        return this.httpClient.get<{ content: AllMasterDatabaseModel[] }>(`${this.url}/master/searchByVersionWinOls`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                versionWinOls: winOls,
            }
        })
    }

    getAllMastersByWinOls(winOls: string) {

        const requests = [
            this.getAllMastersFilteredByMasterWinOls(winOls),
            this.getAllMastersFilteredByVersionWinOls(winOls)
        ];

        forkJoin(requests).subscribe(([masterList1, masterList2]) => {
            this.allMasters.set(this.mergeMasterLists(masterList1['content'], masterList2['content']));
        });

    }

    getAllMastersByWinOlsScrollable(winOls: string) {
        const requests = [
            this.getAllMastersFilteredByMasterWinOls(winOls),
            this.getAllMastersFilteredByVersionWinOls(winOls)
        ];

        forkJoin(requests).subscribe(([masterList1, masterList2]) => {
            this.allMasters.update((allMasters) => [...allMasters, ...this.mergeMasterLists(masterList1['content'], masterList2['content'])]);
        });
    }

    getHomeSearchResultsByWinOls(winOls: string) {
        const requests = [
            this.getAllMastersFilteredByMasterWinOls(winOls),
            this.getAllMastersFilteredByVersionWinOls(winOls)
        ]

        forkJoin(requests).subscribe(([masterList1, masterList2]) => {
            this.searchedResults.set(this.mergeMasterLists(masterList1['content'], masterList2['content']));
        })
    }

    mergeMasterLists(masterList1: AllMasterDatabaseModel[], masterList2: AllMasterDatabaseModel[]): AllMasterDatabaseModel[] {
        const masterMap = new Map<number, AllMasterDatabaseModel>();
        for (const master of masterList1) {
            masterMap.set(master.id, master);
        }
        for (const master of masterList2) {
            masterMap.set(master.id, master);
        }
        return Array.from(masterMap.values());
    }

    triggerMasterUpdateRequest(id: number, masterEl: UpdateMasterModel) {
        return this.httpClient.post<AllMasterDatabaseModel>(`${this.url}/master/updateMaster/${id}`, masterEl, {observe: "response"});
    }

    updateMasterElement(masterEl: UpdateMasterModel, winOls: string, id: number) {
        const dialogRef = this.dialog.open(EditMasterDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {text: `${winOls}`, content: masterEl, id}
        });

        dialogRef.afterClosed().subscribe((data: { closed: boolean, content: UpdateMasterModel }) => {
            if (data.closed) {
                const element = data.content;
                this.triggerMasterUpdateRequest(id, element).subscribe(response => {
                    if (response.status === 200) {
                        const index = this.allMasters().findIndex((value) => value.id === id);
                        Object.assign(this.allMasters()[index], response.body);
                        this.notificationService.showSuccessMessage("Master successfully updated.", "Master updated");
                    } else {
                        this.notificationService.showErrorMessage("Something went wrong!", "Error");
                    }
                });
            }
        });
    }


    deleteMasterFromDatabase(id: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteFileFromDatabaseService.deleteFileFromDatabase(id, tableName).subscribe((response) => {
                    if (response.status === 200) {
                        this.allMasters.update((allMasters) => allMasters.filter((master) => master.id !== id));
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                });
            }
        })
    }

    getAllMastersAdvancedFiltered(filters?: SearchFilterModel[]): Observable<AllMasterDatabaseModel[]> {
        return this.httpClient.post<AllMasterDatabaseModel[]>(`${this.url}/master/listAllMastersFiltered`, filters, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortAttribute(),
                sortDirection: this.sortDirectionValues().direction,

            }
        });
    }

    getFaultyMasters() {
        this.httpClient.get<{ content: AllMasterDatabaseModel[] }>(`${this.url}/master/faultyMasters`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortAttribute(),
                sortDirection: this.sortDirectionValues().direction,

            }
        }).subscribe(response => {
            this.faultyMasters.update((faultyMasters) => [...faultyMasters, ...response["content"]]);
        })
    }

    getFaultyMastersFilteredByEcuBuildName() {
        this.isLoading.set(true);
        this.httpClient.get<{ content: AllMasterDatabaseModel[] }>(`${this.url}/master/faultyMasters`, {
            params: {
                pageNumber: this.pageNumber(),
                pageSize: this.pageSize(),
                sortAttribute: this.sortAttribute(),
                sortDirection: this.sortDirectionValues().direction,

            }
        }).subscribe(response => {
            this.faultyMasters.set(response["content"]);
            this.isLoading.set(false);
        })
    }

    updateFaultyMaster(element: UpdateMasterModel, winOls: string, id: number) {
        const dialogRef = this.dialog.open(EditMasterDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {text: `${winOls}`, content: element, id}
        });

        dialogRef.afterClosed().subscribe((data: { closed: boolean, content: UpdateMasterModel }) => {
            if (data.closed) {
                const masterEl = data.content;
                this.triggerMasterUpdateRequest(id, masterEl).subscribe(response => {
                    if (response.status === 200) {
                        this.faultyMasters.update((faultyMasters) => faultyMasters.filter((master) => master.id !== id));
                        this.notificationService.showSuccessMessage("Faulty Master successfully updated.", "Faulty Master updated.");
                    } else {
                        this.notificationService.showErrorMessage("Something went wrong.", "An Error Occurred.");
                    }
                });
            }
        });
    }


    deleteFaultyMaster(id: number) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteFileFromDatabaseService.deleteFileFromDatabase(id, 'masters').subscribe(response => {
                    if (response.status === 200) {
                        this.faultyMasters.update((faultyMasters) => faultyMasters.filter((master) => master.id !== id));
                        this.notificationService.showSuccessMessage("Successfully Deleted", `Faulty master ${id} deleted from table.`);
                    }
                }, error => {
                    this.notificationService.showErrorMessage("Something went wrong", `${error.error["message"]}`);
                })

            }
        })
    }

    downloadMastersFromTable(id: number, winOls: string, tableName: string) {
        this.filesToDownload.update((prevIds) => [...prevIds, id]);

        const toastObj = this.notificationService.showInfoMessage(`Preparing your ${winOls} file...`, 'Preparing..');

        this.downloadService.downloadFileFromDatabase(id, tableName).subscribe(response => {
            const index = this.filesToDownload().indexOf(id);

            this.filesToDownload.update((files) => files.filter((file, i) => i !== index));

            if (response.status === 200) {

                this.notificationService.clear(toastObj.toastId);

                window.location.href = response.body.url;

                this.notificationService.showSuccessMessage(`<div><span>Your file ${winOls} is downloaded</span></div>`, "Success");

            } else {
                this.notificationService.showErrorMessage('Something went wrong with file.', 'Failed');
            }
            if (response.status === 500) {
                this.notificationService.showErrorMessage("You can't download this file. ", 'Download failed');
            }
        })
    }

    isDownloadInProgress(id: number) {
        return this.filesToDownload().includes(id);
    }

    checkIfEcuIsValid(ecuBuild: string) {
        return this.httpClient.post<{
            valid: boolean
        }>(`${this.url}/ecuBuildNames/verify`, {ecuBuild: ecuBuild}).pipe(map(value => {
            if (value.valid) {
                return null;
            } else {
                return {valid: true}
            }
        }));
    }

    getMasterById(): Observable<AllMasterDatabaseModel> {
        this.masterDetailsIsLoading.set(true);
        return this.httpClient.get<AllMasterDatabaseModel>(`${this.url}/master/${this.masterId()}`).pipe(
            map(value => {
                this.masterItem.set(value as AllMasterDatabaseModel);
                this.ecuBuild.set(value.ecuBuild);
                this.ecuProducer.set(value.ecuProducer);
                this.carImageService.getImage({
                    make: value.vehicleProducer,
                    modelFamily: value.vehicleSeries,
                    modelYear: value.vehicleModelYear,
                    modelRange: value.vehicleModel,
                    width: '1000'
                });
                this.masterDetailsIsLoading.set(false);
                return value;
            })
        );
    }

    updateMasterDetails(masterItem: AllMasterDatabaseModel, id: number) {
        const dialogRef = this.dialog.open(EditMasterDialogComponent, {
            width: "100%",
            maxWidth: "100%",
            data: {text: `${masterItem.winOLSReference}`, content: masterItem as UpdateMasterModel, id}
        });

        dialogRef.afterClosed().subscribe((data: { closed: boolean, content: UpdateMasterModel }) => {
            if (data.closed) {
                const updateMasterElement = data.content;
                this.triggerMasterUpdateRequest(masterItem.id, updateMasterElement).subscribe(response => {
                    this.masterItem.set(response.body);
                });
            }
        });
    }



    deleteMasterItem(masterItemId: number, tableName: string) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "350px",
            data: "Are you sure you want to delete?"
        })

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteFileFromDatabaseService.deleteFileFromDatabase(masterItemId, tableName).subscribe((response) => {
                    if (response.status === 200) {
                        this.allMasters.update((allMasters) => allMasters.filter((master) => master.id !== masterItemId));
                        this.notificationService.showSuccessMessage('Successfully deleted', `${response.body["message"]}`);
                        this.location.back();
                    }
                }, error => {
                    this.notificationService.showErrorMessage('Something went wrong', `${error.error['message']}`);
                });
            }
        })
    }

    masterExists(id: number) {
        return this.httpClient.get(`${this.url}/master/${id}`, {observe: "response"});
    }

    toggleExpandedMasterRow(expandedDetail: AllMasterDatabaseModel) {
        if (expandedDetail == this.expandedMaster()) {
            this.expandedMaster.set(null);
        } else {
            this.expandedMaster.set(expandedDetail);
        }
    }


    uploadMasterIniFile(file: File) {
        if (file) {
            this.myInputs.set('ini', {
                message: '',
                hasError: false,
            })
            this.fileUploadService.uploadMasterIniFile(file, this.masterId()).subscribe((response) => {
                    if (response.type === HttpEventType.UploadProgress) {
                        this.progressWidth.set(Math.round(response.loaded / response.total * 100));
                    } else if (response.type === HttpEventType.Response)
                        if (response.status === 200) {
                            let body = response.body as { masterRecords: AllMasterDatabaseModel, message: string };
                            this.masterItem.set(body.masterRecords);
                            this.notificationService.showSuccessMessage(`<div><span>Master Ini file uploaded.</span></div>`, "Success");
                            this.refreshIniFile();
                            this.getAllMasterRecords();
                            this.getFaultyMastersFilteredByEcuBuildName()
                            this.progressWidth.set(0);
                        }
                }, error => {
                    this.myInputs.set('ini', {
                        message: error.error.message,
                        hasError: true
                    })
                    this.progressWidth.set(0);
                }
            )
        }
    }

    refreshIniFile() {
        this.resetSignal.set(true);
        this.myInputs = new Map<string, CustomMasterUploadFileValidations>([
            ["ini", {
                message: '',
                hasError: false,
            }],
        ]);
    }

    increasePageNumber() {
        this.pageNumber.update((pageNumber) => pageNumber + 1);
    }

    restartTableValues() {
        this.pageNumber.set(0);
        this.pageSize.set(50);
        this.faultyMasters.set([]);
        this.displayedColumns.set([]);
        this.displayedTableHeaders.set([]);
        this.displayedVersionColumns.set([]);
    }
}
