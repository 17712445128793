export class AllMasterDatabaseModel {
    public id: number;
    public sha1: string;
    public sha256: string;
    public winOLSReference: string
    public masterFileBlobZipReference: string;
    public numberOfVersions: number;
    public uploadedBy: string;
    public dateUploaded: string;
    public clientName: string
    public clientNumber: string
    public clientLicencePlace: string;
    public vehicleType: string;
    public vehicleProducer: string;
    public vehicleSeries: string;
    public vehicleModel: string;
    public vehicleModelYear: string;
    public ecuUse: string;
    public ecuProducer: string;
    public ecuBuild: string;
    public ecuProdNr: string;
    public ecuSoftwareVersion: string;
    public ecuSoftwareVersionVersion: string;
    public engineName: string;
    public engineDisplacement: string;
    public vin: string;
    public vehicleBuild: string;
    public vehicleCharacteristic: string;
    public ecuStgNr: string;
    public ecuProcessor: string;
    public engineProducer: string;
    public engineType: string;
    public engineMaxTorque: string;
    public engineEmission: string;
    public engineGear: string;
    public projectCreatedBy: string;
    public projectChangedBy: string;
    public outputPs: string;
    public outputKw: string;
    public engineTransmission: string;
    public readingHardware: string;
    public resellerProjectDetails: string;
    public additionalInfo: string;
    public engineEmissionStandard: string;
    public resellerProjectType: string;
    public projectComment: string;
    public versionComment: string;
    public  softwareNumber: string;
    public projectType:string;
    public userDef1: string;
    public userDef2: string;
    public userDef3: string;
    public userDef4: string;
    public userDef5: string;
    public userDef6: string;
    public userDef7: string;
    public userDef11: string;
    public userDef12: string;
    public userDef13: string;
    public userDef14: string;
    public userDef15: string;
    public userDef16: string;
    public userDef17: string;
    public userDef18: string;
    public userDef19: string;
    public userDef20: string;
    public resellerCredits: string;
    public creditType: string;

    constructor() {
        this.id = 0;
        this.winOLSReference = "winOLSReference";
        this.ecuProducer = "ecuProducer";
        this.ecuBuild = "ecuBuild";
        this.engineName = "engineName";
        this.numberOfVersions = 0;
        this.dateUploaded = 'dateUploaded';
        this.clientName = "clientName";
        this.clientNumber = "clientNumber";
        this.clientLicencePlace = "clientLicencePlace";
        this.vehicleType = "vehicleType";
        this.vehicleProducer = "vehicleProducer";
        this.vehicleSeries = "vehicleSeries";
        this.vehicleModel = "vehicleModel";
        this.vehicleModelYear = "vehicleModelYear";
        this.ecuUse = "ecuUse";
        this.ecuProdNr = "ecuProdNr";
        this.ecuSoftwareVersion = "ecuSoftwareVersion";
        this.ecuSoftwareVersionVersion = "ecuSoftwareVersionVersion";
        this.engineDisplacement = "engineDisplacement";
        this.vin = "vin";
        this.vehicleBuild = "vehicleBuild";
        this.vehicleCharacteristic = "vehicleCharacteristic";
        this.ecuStgNr = "ecuStgNr";
        this.ecuProcessor = "ecuProcessor";
        this.engineProducer = "engineProducer";
        this.engineType = "engineType";
        this.engineMaxTorque = "engineMaxTorque";
        this.engineEmission = "engineEmission";
        this.engineGear = "engineGear";
        this.projectCreatedBy = "projectCreatedBy";
        this.projectChangedBy = "projectChangedBy";
        this.outputPs = "outputPs";
        this.outputKw = "outputKw";
        this.engineTransmission = "engineTransmission";
        this.readingHardware = "readingHardware";
        this.resellerProjectDetails = "resellerProjectDetails";
        this.additionalInfo = "additionalInfo";
        this.engineEmissionStandard = "engineEmissionStandard";
        this.uploadedBy = "uploadedBy";
        this.masterFileBlobZipReference = "masterFileBlobZipReference";
        this.sha1 = "sha1";
        this.sha256 = "sha256";
        this.resellerProjectType = "resellerProjectType";
        this.projectComment = "projectComment";
        this.versionComment = "versionComment";
        this.softwareNumber = "softwareNumber";
        this.projectType = "projectType";
        this.userDef1 = "userDef1";
        this.userDef2 = "userDef2";
        this.userDef3 = "userDef3";
        this.userDef4 = "userDef4";
        this.userDef5 = "userDef5";
        this.userDef6 = "userDef6";
        this.userDef7 = "userDef7";
        this.userDef11 = "userDef11";
        this.userDef12 = "userDef12";
        this.userDef13 = "userDef13";
        this.userDef14 = "userDef14";
        this.userDef15 = "userDef15";
        this.userDef16 = "userDef16";
        this.userDef17 = "userDef17";
        this.userDef18 = "userDef18";
        this.userDef19 = "userDef19";
        this.userDef20 = "userDef20";
        this.resellerCredits = "resellerCredits";
        this.creditType = "creditType";

    }
}
